
<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item"><a href="/activities">Reservas</a></li>
      <li class="breadcrumb-item active" aria-current="page">Nova</li>
    </ol>
  </nav>
  <Form :save="parentMethod" />
</template>

<script>
import Form from './Form.vue';
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  components: {
    Form,
  },
  methods: {
    async parentMethod(activity) {
      ApiAxios.post(`activities`, { activity })
        .then((res) => {
          sAlert({ type: 'success', text: 'Registro gravado com sucesso.' });
          this.$router.push({ name: 'activityEdit', params: { id: res.data.activity.id }});
        })
        .catch((e) => {
          if (e.response.status == 403) {
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
    }
  },  
}
</script>  
