
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <p>ATENÇÃO! RESERVAS NÃO HABILITADAS PELO CLUBE</p>
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" disabled autofocus="autofocus" required="required">
        </div>
      </div>

      
      <div style="float: right;">
        <!-- <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button> -->
        <router-link class="btn btn-default" style="margin-left:10px;" to="/reservations/locationsavailable">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';
// import InputSelect from '../commons/InputSelect.vue';
// import SimpleDataTable from '../commons/SimpleDataTable.vue';
// import FormAssociate from './FormAssociate.vue';

export default {
  props: {
    save: Function,
  },
  // components: {
  //   InputSelect,
  //   SimpleDataTable,
  //   FormAssociate
  // },
  data() {
    return {
      activity: {},
      validationErrors: [],
      checkedDays: [],
      tableOptions: {
        endpoint: `activities/${this.$route.params.id}/associates`,
        object: 'activityAssociates',
        fields: [ 
          { 'name': 'id', 'caption': '#' },
          { 'name': 'name', 'relation': 'associate', 'caption': 'Associado' },
          { 'name': 'titlenumber', 'relation': 'associate', 'caption': 'Título' },
          { 'name': 'associatenumber', 'relation': 'associate', 'caption': 'Cartão' },
        ],
        filter: 'name',
      }
    };
  },
  mounted() {
    // this.fetchGetLocation();
  },
  methods: {
    refetchAssociates() {
      this.$refs.listAssociates.fetchGetData();
    },
    async fetchGetLocation() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`activities/${this.$route.params.id}`);
      this.activity = res.data.activity;
      if (this.activity.days) this.checkedDays = this.activity.days;
      this.$root.loadingVisible = false;
    },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }

      this.activity.days = this.checkedDays;
      
      this.save( this.activity );
    },
    validate() {
      const errors = [];
      if (!this.activity.name) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
